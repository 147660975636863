<template>
  <div id="page-user-list">
    <div class="pt-10">
      <vx-card v-if="centerDetails" class="mb-base">
        <div class="flex flex-wrap justify-between items-center">
          <div class="vx-breadcrumb md:block hidden">
            <ul class="flex flex-wrap items-center">
              <li class="inline-flex item-center">
                <router-link :to="{ name: 'director-learning-centre-list' }"
                  >Manage</router-link
                >
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex item-center">
                <router-link :to="{ name: 'director-learning-centre-list' }"
                  >Early Learning Centre</router-link
                >
                <span class="breadcrumb-separator mx-2">
                  <span class="feather-icon select-none relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevrons-right"
                    >
                      <polyline points="13 17 18 12 13 7" />
                    </svg>
                  </span>
                </span>
              </li>
              <li class="inline-flex item-center">
                <a href="javascript:void(0)" class>{{ centerDetails.name }}</a>
              </li>
            </ul>
          </div>
          <div class="flex my-5 mt-0">
            <div class="ml-auto">
              <vs-button @click="editCenter(centerDetails)">Edit</vs-button>
              <!--              <vs-button @click="deleteCenterData(centerId)" class="ml-8" color="danger">Delete</vs-button>-->
              <vs-button
                @click="statusToggle(centerId)"
                class="ml-4"
                :color="!centerDetails.isActive ? 'success' : 'danger'"
              >
                {{ !centerDetails.isActive ? "Active" : "InActive" }}
              </vs-button>
            </div>
          </div>
        </div>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
          class="admin-detail-wrap"
        >
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            class="vs-con-loading__container mt-5"
            vs-xs="12"
            vs-sm="5"
            vs-lg="4"
          >
            <div class="w-full p-5">
              <div
                class="w-full mx-auto flex items-center justify-center5"
              >
                <img
                  v-bind:src="centerDetails.logo"
                  alt="img"
                  class="responsive"
                  v-if="centerDetails.logo && centerDetails.logo != 'test.jop'"
                />
                <img
                  v-bind:src="$defaultImage"
                  alt="img"
                  class="responsive"
                  v-else
                />
              </div>
            </div>
          </vs-col>
          <vs-col vs-xs="12" vs-sm="7" vs-lg="8" class="py-10">
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Centre Name</label>
              <p class="text-dark">{{ centerDetails.name }}</p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Street Address</label>
              <p class="text-dark">
                {{
                  "undefined" != typeof centerDetails.address
                    ? centerDetails.address.displayAddress
                    : ""
                }}
              </p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Town</label>
              <p class="text-dark">
                {{
                  "undefined" != typeof centerDetails.address
                    ? centerDetails.address.suburb
                    : ""
                }}
              </p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Phone Number</label>
              <p class="text-dark">{{ centerDetails.phoneNumber }}</p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Email</label>
              <p class="text-dark">{{ centerDetails.email }}</p>
            </div>
            <div class="w-full p-5">
              <label class="font-semibold text-dark">Country</label>
              <p class="text-dark">
                {{ centerDetails.country ? centerDetails.country.name : "" }}
              </p>
            </div>
            <div class="w-full m-5">
              <label class="font-semibold text-dark">Post Code</label>
              <p class="text-dark">
                {{
                  centerDetails.address ? centerDetails.address.postCode : ""
                }}
              </p>
            </div>
            <div class="w-full p-5">
              <label class="font-bold">Status:</label>
              <vs-button
                class="ml-4"
                :color="centerDetails.isActive ? 'success' : 'danger'"
              >
                {{ centerDetails.isActive ? "Active" : "InActive" }}
              </vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vx-card>

      <vx-card
        class="mb-base directors-card"
        v-if="!director.isRegionalDirector"
      >
        <div class="mb-5">
          <vs-table
            max-items="5"
            pagination
            :data="regionalDirectors"
            :noDataText="noDataText"
          >
            <div slot="header" class="w-full">
              <div class="flex flex-wrap items-center">
                <div class="w-full md:w-3/4">
                  <div class="card-title">
                    <h2>Regional Directors</h2>
                  </div>
                </div>
              </div>
            </div>
            <template slot="thead">
              <vs-th>Regional Director Name</vs-th>
              <vs-th>Country</vs-th>
              <vs-th>Region</vs-th>
              <vs-th>No. Assigned Centres</vs-th>
              <vs-th>Action</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in regionalDirectors">
                <vs-td :data="tr.fullName">{{
                  tr.fullName | capitalize
                }}</vs-td>
                <vs-td :data="tr.country.name" v-if="tr.country">
                  {{ tr.country.name | capitalize }}
                </vs-td>
                <vs-td v-else>
                  -
                </vs-td>
                <vs-td :data="tr.address.state" v-if="tr.address">{{
                  tr.address.state | capitalize
                }}</vs-td>
                <vs-td v-else>
                  -
                </vs-td>
                <vs-td :data="tr.regionalDirectorLearningCenters">
                  {{ tr.regionalDirectorLearningCenters.length }}
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <router-link
                    :to="{
                      name: 'regional-director-view',
                      params: { id: tr._id }
                    }"
                    class="underline"
                    >View
                  </router-link>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>

      <vx-card class="mb-base directors-card">
        <div class="mb-5">
          <vs-table
            max-items="5"
            pagination
            :data="centerAdmin"
            :noDataText="noDataText"
          >
            <div slot="header" class="w-full">
              <div class="flex flex-wrap items-center">
                <div class="w-full md:w-3/4">
                  <div class="card-title">
                    <h2>Centre Administration Team</h2>
                  </div>
                </div>
                <div class="w-full md:w-1/4">
                  <vs-button
                    class="w-full md:w-auto float-right"
                    color="primary"
                    type="filled"
                    @click="addAdmin(centerId)"
                    >Add Admin
                  </vs-button>
                </div>
              </div>
            </div>
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Role</vs-th>
              <vs-th>Username</vs-th>
              <vs-th>Change password</vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.fullName">{{
                  tr.fullName | capitalize
                }}</vs-td>
                <vs-td :data="tr.role">
                  <p>Admin</p>
                </vs-td>
                <vs-td :data="tr.email">{{ tr.email }}</vs-td>
                <vs-td :data="tr._id">
                  <a href="#" @click="resetPassword(tr._id)">Reset Password</a>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <router-link
                    :to="{
                      name: 'director-learning-centre-admin-view',
                      params: { id: tr._id, center: centerId }
                    }"
                    class="underline"
                    >View
                  </router-link>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>
      <vx-card class="mb-base directors-card">
        <div class="mb-5">
          <vs-table :data="centerListDetails">
            <div slot="header" class="w-full">
              <div class="flex flex-wrap items-center">
                <div class="w-full md:w-3/4">
                  <div class="card-title">
                    <h2>Centre Details</h2>
                  </div>
                </div>
              </div>
            </div>
            <template slot="thead">
              <vs-th>Name</vs-th>
              <vs-th>Number</vs-th>
              <vs-th>Action</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].name">{{
                  data[indextr].name | capitalize
                }}</vs-td>
                <vs-td :data="data[indextr].number">{{
                  data[indextr].number
                }}</vs-td>
                <vs-td class="whitespace-no-wrap">
                  <router-link
                    :to="{
                      name: 'director-room-list',
                      params: { id: centerId }
                    }"
                    class="underline"
                    v-if="data[indextr].type == 'rooms'"
                    >View
                  </router-link>
                  <router-link
                    :to="{
                      name: 'director-learning-centre-view-list',
                      params: { id: centerId, type: data[indextr].type }
                    }"
                    class="underline"
                    v-else
                    >View
                  </router-link>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

export default {
  components: {
    vSelect
  },
  data() {
    return {
      addNewDataSidebar: false,
      addCenterSidebar: false,
      addAdminSidebar: false,
      sidebarData: {},
      centerSidebarData: {},
      adminSidebarData: {},
      centerDetails: {},
      admins: [],
      centerAdmin: [],
      centerListDetails: {},
      centerId: this.$route.params.id,
      noDataText: "Currently no centre admin available",
      regionalDirectors: []
    };
  },
  watch: {},
  computed: {
    director() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    ...mapActions("center", [
      "getDirectorCenterById",
      "getDirectorCenterAdminList",
      "deleteDirectorCenter",
      "getDirectorCenterDetailList",
      "resetUserPassword",
      "updateCenterStatus"
    ]),
    ...mapActions("director", ["getRegionalDirectorsOfLearningCentre"]),

    resetPassword(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure you want reset password for this user?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!"
      }).then(result => {
        if (result.value) {
          this.acceptPasswordResetAlert(id);
        }
      });
    },
    acceptPasswordResetAlert(id) {
      this.resetUserPassword(id)
        .then(() => {
          this.showChangePasswordSuccess();
        })
        .catch(err => {
          console.error(err);
        });
    },
    showChangePasswordSuccess() {
      this.$vs.notify({
        title: "Success",
        text: "Reset password mail sent to user.",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "success"
      });
    },

    async getDirectorCenterDetails(id) {
      this.$vs.loading();
      await this.getDirectorCenterById(id)
        .then(async res => {
          if (res.status === 200) {
            this.centerDetails = await res.data.data;
          }
          this.$vs.loading.close();
        })
        .catch(err => {
          this.$vs.loading.close();
          if (err.response.status === 403) {
            Swal.fire({
              title: "No Access",
              html: `
                  <div>
                    You dont have permission to visit this page. Please upgrade your package.
                  </div>`,
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33"
            }).then(result => {
              this.$router.push("/director/upgrade-package");
            });
          }
        });
    },
    getDirectorAdminList(id) {
      this.getDirectorCenterAdminList(id).then(res => {
        this.centerAdmin = res.data.data;
      });
    },
    getDirectorCenterList(id) {
      this.getDirectorCenterDetailList(id).then(res => {
        this.centerListDetails = res.data.data;
      });
    },
    addAdmin(centerId) {
      this.$router.push({
        name: "director-learning-centre-admin-add",
        params: { id: centerId }
      });
    },
    editCenter(data) {
      this.centerSidebarData = data;
      this.$router.push({
        name: "director-learning-centre-edit",
        params: { id: data._id }
      });
    },
    async statusToggle(centerId) {
      Swal.fire({
        title: "Are you sure you want to update status of this centre?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!"
      }).then(result => {
        if (result.value) {
          this.updateStatus(centerId);
        }
      });
    },
    async updateStatus(centerId) {
      await this.updateCenterStatus(centerId)
        .then(response => {
          this.showMessage(
            "Success",
            "Status updated successfully.",
            "success"
          );
          this.getDirectorCenterDetails(centerId);
        })
        .catch(error => {
          this.showMessage("Error", error.message, "danger");
          console.log(error);
        });
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    deleteCenterData(id) {
      Swal.fire({
        title: "Are you sure you want to delete this centre?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          this.acceptDeleteAlert(id);
        }
      });
    },
    acceptDeleteAlert(id) {
      this.$vs.loading();
      this.deleteDirectorCenter(id)
        .then(res => {
          if (res.status === 200) {
            this.centers = this.$store.state.center.centersOfGroup;
            this.$vs.loading.close();
            this.showMessage(
              "Success",
              "Center deleted successfully.",
              "success"
            );
          }
          this.$router.push({ name: "director-learning-centre-list" });
        })
        .catch(err => {
          this.$vs.loading.close();
          // console.error(err)
        });
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },

    toggleAdminSidebar(val = false) {
      this.addAdminSidebar = val;
    },
    updateData(data) {
      this.centerDetails.name = data.companyName;
      this.centerDetails.fullName = data.fullName;
      this.centerDetails.phoneNumber = data.contactNumber;
      this.centerDetails.email = data.email;
      this.centerDetails.country.name = data.country;
      this.centerDetails.address.state = data.state;
      this.centerDetails.logo = data.logo;
    },
    refreshData(val = false) {
      if (val) {
        let id = this.$route.params.id;
        this.getDirectorCenterDetails(id);
        this.getDirectorAdminList(id);
        this.getDirectorCenterList(id);
      }
    }
  },
  mounted() {},
  async created() {
    let id = this.$route.params.id;
    await this.getDirectorCenterDetails(id);
    await this.getDirectorAdminList(id);
    await this.getDirectorCenterList(id);
    await this.getRegionalDirectorsOfLearningCentre(this.$route.params.id).then(
      async res => {
        this.regionalDirectors = res.data.data;
      }
    );
  }
};
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

.deleteBtn {
  background-color: white;
  color: black;
  border: 2px solid #f44336;
}
</style>
